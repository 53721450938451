<template>
  <Layout style="background-color:white;">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">

        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Ara:
                    <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2">
                    </b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive">
              <b-table :items="transactionData" :fields="fields" responsive="sm" :per-page="perPage"
                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">

                <template v-slot:cell(action)="data">
                  <b-button-group>
                    <b-button size="sm" v-b-modal.modal-1 variant="success" @click="Duzenle(data.item)">Düzenle</b-button>

                  </b-button-group>
                </template>
                <template v-slot:cell(aktifpasif)="data">
                  
                  <b-form-checkbox v-model="data.item.aktifpasif" name="check-button" @change="AktifPasif(data.item)" switch></b-form-checkbox>

                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <b-modal id="modal-1" title="Yetkili & Bayi Düzenle" v-if="modaldata!=null" hide-footer>
          <div class="row">
              <div class="col-md-6">
                  <b-form-group id="fieldset-1" label="E-mail" label-for="input-1">
                      <b-form-input id="input-1" size="sm" v-model="modaldata.email" trim></b-form-input>
                  </b-form-group>
                  <b-form-group id="fieldset-1" label="Adi" label-for="input-1">
                      <b-form-input id="input-1" size="sm" v-model="modaldata.adi" trim></b-form-input>
                  </b-form-group>
                  <b-form-group id="fieldset-1" label="Soyadi" label-for="input-1">
                      <b-form-input id="input-1" size="sm" v-model="modaldata.soyadi" trim></b-form-input>
                  </b-form-group>
                  <b-form-group id="fieldset-1" label="Yeni Şifre" label-for="input-1">
                      <b-form-input id="input-1" type="password" size="sm" v-model="modaldata.yenisifre" trim></b-form-input>
                  </b-form-group>
              </div>
              <div class="col-md-6">
                  <b-form-group id="fieldset-1" label="Dist" label-for="input-1">
                     <b-form-select v-model="modaldata.dist" :options="distoptions"></b-form-select>
                  </b-form-group>
                  <b-form-group id="fieldset-1" label="Role" label-for="input-1">
                    <b-form-select v-model="modaldata.role" :options="options" size="sm"></b-form-select>
                  </b-form-group>
                  
                  <b-form-group id="fieldset-1"  label-for="input-1">
                    <b-button variant="primary" @click="Guncelle">Güncelle</b-button>
                  </b-form-group>
                  
              </div>
          </div>
      </b-modal>
    </div>
  </Layout>
</template>
<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import {axiosInstance} from '../../../helpers/axios';
  export default {
    components: {
      Layout,
      PageHeader,
    },
    data() {
      return {
        title: "Yetkili & Bayi Listele",
        items: [{
            text: "Anasayfa"
          },
          {
            text: "Yetkili & Bayi Listele",
            active: true
          }
        ],
        transactionData: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 15,
        pageOptions: [5, 10, 15, 25, 50],
        filter: null,
        filterOn: [],
        sortBy: "orderid",
        sortDesc: false,
        fields: [{
            key: "action",
            label: "İşlemler"
          },{
            key: "aktifpasif",
            label: "Aktif/Pasif"
          },{
            key: "email",
            sortable: true,
            label: "E-mail"
          },
          {
            key: "adisoyadi",
            sortable: true,
            label: "Adi Soyadi"
          },
          {
            key: "role",
            sortable: true,
            label: "Yetki"
          },
          {
            key: "giristarihi",
            sortable: true,
            label: "İlk Giriş Tarihi"
          },
          {
            key: "dist",
            sortable: true,
            label: "Dist"
          }
        ],
        options: [
          { value: "admin", text: 'Tam Yetkili' },
          { value: "basicadmin", text: 'Dist Yetkisi' }
        ],
        modaldata:null,
        distoptions:[],
      }
    },
    created() {
      var vm = this;
      vm.totalRows = vm.transactionData.length;
      vm.List();
      vm.dist_list();
    },
    computed: {
      rows() {
        return this.transactionData.length;
      },
    },
    methods: {
      dist_list()
        {
            var vm = this;
            try {

                axiosInstance.post("dist/list").then((response)=>{

                     if(response.data.error==true)
                        {
                        vm.$bvToast.toast(response.data.message, {
                            title: `Uyari !`,
                            variant: "warning",
                            solid: true
                        });
                        }else{
                            response.data.data.forEach(element => {
                                vm.distoptions.push({
                                  value:element.id,
                                  text:element.adi,
                                })
                            });
                        }

                }).catch((error)=>{

                    vm.$bvToast.toast(error, {
                            title: `Uyari !`,
                            variant: "warning",
                            solid: true
                        });
                    
                })
                
            } catch (error) {

                vm.$bvToast.toast(error, {
                            title: `Uyari !`,
                            variant: "warning",
                            solid: true
                        });
                
            }
      },
      Guncelle()
      {
        var vm = this;
        try {
            axiosInstance.post("kullanicilar/yetkili/guncelle",{
              params:vm.modaldata
            }).then((response)=>{

              if(response.data.error==true)
              {
                vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz ", {
                  title: `Uyari !`,
                  variant: "warning",
                  solid: true
                });
              }else{
                vm.$bvToast.toast("Güncelleme başarılı,Lisans Güncellendi", {
                  title: `Bildirim !`,
                  variant: "info",
                  solid: true
                });
                vm.List();
              }
            
            
            }).catch(error=>{
              vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
                title: `Uyari !`,
                variant: "warning",
                solid: true
              });
            })

            
          } catch (error) {
            vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
                title: `Uyari !`,
                variant: "warning",
                solid: true
              });
          }

      },
      Duzenle(params)
      {
          this.modaldata = params;
          this.modaldata.yenisifre = "";
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      List()
      {
        var vm = this;
        vm.transactionData=[];
        try {

          axiosInstance.get("kullanicilar/yetkili/listele").then((response)=>{
            if(response.data.error==true)
            {
              vm.$bvToast.toast("Listeleme Başarısız Tekrar deneyiniz ", {
                title: `Uyari !`,
                variant: "warning",
                solid: true
              });

            }else{

              if(response.data.data.length>0)
              {
                response.data.data.forEach(element => {
                  vm.transactionData.push({
                    email: element.email,
                    adisoyadi: element.adi+" "+element.soyadi,
                    adi:element.adi,
                    soyadi:element.soyadi,
                    role:element.role,
                    giristarihi: element.giristarihi,
                    lisansadet: element.lisansadet,
                    dist: element.dist,
                    aktifpasif:(element.status==1) ? true : false,
                    id:element.id,
                    action:""
                  });    
                });
              }

            }
          }).catch((error)=>{
            console.log(error);
          })
          
        } catch (error) {
          console.log(error);
        }
      },
      AktifPasif(params)
      {
          var vm = this;
          try {

            axiosInstance.post("kullanicilar/yetkili/aktifpasifchange",{
              aktifpasif:params.aktifpasif,
              id:params.id

          }).then((response)=>{

           if(response.data.error==true)
           {
             vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz ", {
               title: `Bildirim !`,
               variant: "warning",
               solid: true
             });
           }else{

             vm.$bvToast.toast(response.data.message, {
               title: `Bildirim !`,
               variant: "warning",
               solid: true
             });

           }

          }).catch((error)=>{
            vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
          });
            
          } catch (error) {
            vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
          }
      }
    },
  }
</script>